ion-modal {
  --ion-background-color: var(--color-modal-background);
  --backdrop-opacity: var(--opacity-modal-backdrop);
  &::part(backdrop) {
    background-color: var(--color-modal-backdrop);
  }

  &.security-modal {
    --backdrop-opacity: 1;
    &::part(content) {
      @apply h-full
      w-full;
    }
  }

  &.modal-sheet {
    &::part(content) {
      @apply rounded-t-3xl;
    }

    semmie-modal-scroll {
      .scroll-container {
        @apply rounded-t-3xl;
      }
    }

    & semmie-content-container {
      @apply pt-0 #{!important};
      .wrapper {
        @apply pt-0 #{!important};
      }
      .content-container {
        @apply max-w-full #{!important};
      }
    }

    &.form-modal {
      & semmie-content-container {
        .content-container {
          @apply max-w-full #{!important};
        }
      }
    }
  }

  &.full-size-modal {
    --width: 100%;
    --height: 100%;

    &::part(content) {
      @apply lg:rounded-none;
    }

    & semmie-content-container {
      .content-container {
        @apply max-w-lg #{!important};
      }
    }
  }

  &.full-size-side-modal {
    &.md {
      --height: calc(98vh - var(--safe-area-inset-top));
    }
    @screen sm {
      --max-width: 375px;
    }
    @screen lg {
      --height: calc(100% - var(--safe-area-inset-top) - var(--safe-area-inset-bottom)) !important;
    }

    &::part(content) {
      @apply lg:rounded-none
      lg:rounded-l-3xl;
    }

    semmie-modal-scroll {
      .scroll-container {
        @apply lg:rounded-l-3xl
        lg:rounded-r-none;
      }
    }

    &.full-size-side-modal-left {
      // add an element to form the drop shadow over the modal
      // because the modal is above the tab bar
      &::after {
        @apply absolute
          -left-10
          top-0
          z-10
          h-full
          w-10
          rounded-r-3xl;
        content: '';
        box-shadow: var(--shadow-tab-bar);
      }

      // position the modal with a margin equal to the tab bar width
      @apply lg:landscape:ml-tab-bar;

      // set the corder rounding of the modal to the right
      &::part(content) {
        @apply lg:rounded-none
        lg:rounded-r-3xl;
      }

      semmie-modal-scroll {
        .scroll-container {
          @apply lg:rounded-r-3xl
          lg:rounded-r-none;
        }
      }

      semmie-sticky-close {
        @apply lg:left-auto
        lg:right-4;
      }
    }
  }

  &.auto-size-modal {
    --height: auto;

    &::part(content) {
      @apply lg:bottom-auto
      lg:w-96
      lg:rounded-3xl
      lg:pb-4;
    }

    .ion-page {
      position: relative !important;
      display: block !important;
      contain: content !important;
      height: auto !important;

      ion-content,
      .info-modal-content {
        max-height: calc(98vh - var(--safe-area-inset-top));
        overflow: auto;
      }
    }
    &.md {
      .ion-page {
        ion-content,
        .info-modal-content {
          max-height: calc(98vh - var(--safe-area-inset-top));
        }
      }
    }
    semmie-content-container-bottom {
      @apply lg:pb-4;
    }

    .modal-content {
      @apply flex;
      @apply flex-col;
      @apply justify-center;
      &.safeAreaBottom {
        padding-bottom: var(--safe-area-inset-bottom);
      }
    }
  }

  &.selection-modal {
    cdk-virtual-scroll-viewport {
      .cdk-virtual-scroll-content-wrapper {
        padding-bottom: var(--safe-area-inset-bottom);
      }
    }
  }

  &.scanner-modal {
    --ion-background-color: transparent;
    @apply before:absolute
    before:left-0
    before:top-0
    before:block
    before:h-52
    before:w-full
    before:content-[''];

    @screen lg {
      --height: calc(100vh - var(--safe-area-inset-top) - var(--safe-area-inset-bottom)) !important;
    }

    &:before {
      background-color: var(--color-content-background);
    }

    &::part(backdrop) {
      @apply h-52;
    }

    &::part(content) {
      @apply w-full;
    }
  }
}

.keyboard-is-open {
  ion-modal {
    &.md {
      &::part(content) {
        bottom: calc(var(--keyboard-height)) !important;
      }
    }

    &.android-keyboard-support,
    &.selection-modal {
      // handling modal size on android with keyboard open (for example country select modal)
      &.md {
        ion-content,
        // override for other fix in `global.scss`
        ion-content.md:not(:has(ion-content.md)) {
          --offset-bottom: 0px !important;
        }
        --height: calc(98vh - var(--safe-area-inset-top) - var(--keyboard-height)) !important;

        // exception for auto-size-modals
        &.auto-size-modal {
          --height: auto !important;
          .info-modal-content {
            max-height: calc(98vh - var(--safe-area-inset-top) - var(--keyboard-height)) !important;
          }
        }
      }
    }
  }
}
