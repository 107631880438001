@use 'mixins' as *;
:root {
  ion-toast.onyxx-toaster {
    --border-radius: theme(borderRadius[2xl]);
    --border-width: theme(borderWidth.DEFAULT);
    --border-style: solid;
    --background: var(--color-toaster-default-background);
    --border-color: var(--color-toaster-default-border);
    --color: var(--color-toaster-default-text);
    --max-width: 24rem;

    &.md {
      @include SafeAreaTop(top);
      @include SafeAreaBottom(bottom);
    }

    &::part(button) {
      color: var(--color-toaster-default-button);
      @apply text-sm;
      &:hover {
        background: transparent;
      }
    }

    &::part(container) {
      @apply py-1.5;
    }

    &::part(icon) {
      @apply mt-3.5
      self-start;
    }

    &::part(header) {
      @apply text-h4;
    }

    &::part(message) {
      @apply text-base_3;
    }

    &-default {
      --background: var(--color-toaster-default-background);
      --border-color: var(--color-toaster-default-border);
      --color: var(--color-toaster-default-text);
      &::part(button) {
        color: var(--color-toaster-default-button);
      }
    }

    &-success {
      --background: var(--color-toaster-success-background);
      --border-color: var(--color-toaster-success-border);
      --color: var(--color-toaster-success-text);
      &::part(button) {
        color: var(--color-toaster-success-button);
      }
    }

    &-warning {
      --background: var(--color-toaster-warning-background);
      --border-color: var(--color-toaster-warning-border);
      --color: var(--color-toaster-warning-text);
      &::part(button) {
        color: var(--color-toaster-warning-button);
      }
    }

    &-danger {
      --background: var(--color-toaster-danger-background);
      --border-color: var(--color-toaster-danger-border);
      --color: var(--color-toaster-danger-text);
      &::part(button) {
        color: var(--color-toaster-danger-button);
      }
    }
  }
}
